@import "richtext-editor.scss";
html {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial,
    Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  tab-size: 4;
  scroll-behavior: smooth;
}

body {
  font-family: inherit;
  line-height: inherit;
  margin: 0;
}

h1,
h2,
p,
pre {
  margin: 0;
  word-break: break-word;
}

p {
  margin-bottom: 10px;
  word-break: break-word;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}

h1,
h2 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

svg {
  display: block;
  vertical-align: middle;
  shape-rendering: auto;
  text-rendering: optimizeLegibility;
}

pre {
  background-color: rgba(55, 65, 81, 1);
  border-radius: 0.25rem;
  color: rgba(229, 231, 235, 1);
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  overflow: scroll;
  padding: 0.5rem 0.75rem;
}

.shadow {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.rounded {
  border-radius: 1.5rem;
}

.wrapper {
  width: 100%;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 768px;
  padding-bottom: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: rgba(55, 65, 81, 1);
  width: 100%;
}

.list-item-link {
  align-items: center;
  border-radius: 0.75rem;
  display: flex;
  margin-top: 1rem;
  padding: 1rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  width: 100%;
}

.list-item-link svg:first-child {
  margin-right: 1rem;
  height: 1.5rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  width: 1.5rem;
}

.list-item-link>span {
  flex-grow: 1;
  font-weight: 400;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.list-item-link>span>span {
  color: rgba(107, 114, 128, 1);
  display: block;
  flex-grow: 1;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.list-item-link svg:last-child {
  height: 1rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  width: 1rem;
}

.list-item-link:hover {
  color: rgba(255, 255, 255, 1);
  background-color: hsla(162, 47%, 50%, 1);
}

.list-item-link:hover>span>span {
  color: rgba(243, 244, 246, 1);
}

.list-item-link:hover svg:last-child {
  transform: translateX(0.25rem);
}

.button-pill {
  padding: 1.5rem 2rem;
  transition-duration: 300ms;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  align-items: center;
  display: flex;
}

.button-pill svg {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  flex-shrink: 0;
  width: 3rem;
}

.button-pill>span {
  letter-spacing: -0.025em;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.button-pill span span {
  display: block;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.25rem;
}

.button-pill:hover svg,
.button-pill:hover {
  color: rgba(255, 255, 255, 1) !important;
}

details {
  align-items: center;
  display: flex;
  margin-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}

details pre>span {
  color: rgba(181, 181, 181, 1);
  display: block;
}

summary {
  border-radius: 0.5rem;
  display: flex;
  font-weight: 400;
  padding: 0.5rem;
  cursor: pointer;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

summary:hover {
  background-color: rgba(243, 244, 246, 1);
}

summary svg {
  height: 1.5rem;
  margin-right: 1rem;
  width: 1.5rem;
}

b {
  font-weight: 600;
}

blockquote {
  display: block;
  height: auto;
  margin: 20px 0 !important;
  padding: 5px 0 0 40px;
  border-left: 1px solid #af8232;
}

blockquote::before {
  content: url('https://a.storyblok.com/f/148087/x/6079b847a9/vector.svg');
  display: block;
  width: 26px;
  height: 20px;
  margin: 10px 5px 10px 0;
}

/* React Slider Styles */
.rc-slider-mark-text:first-child {
  margin-left: 10px;
}

.rc-slider-mark-text:last-child {
  margin-left: -12px;
  white-space: nowrap;
}

html:has(.iframe) {
  overflow: hidden;
}

@media (min-width: 1024px) {
  .linear-gradient-gray50-to-stone-gray {
    background-image: linear-gradient(90deg, #FAFAFA 50%, #D5D2CA 50%);
  }

  .linear-gradient-gray50-to-pastel-blue {
    background-image: linear-gradient(90deg, #FAFAFA 50%, #7AA8D1 50%);
  }

  .linear-gradient-gray50-to-gold-creme {
    background-image: linear-gradient(90deg, #FAFAFA 50%, #F5F0E6 50%);
  }

  .linear-gradient-gray50-to-khaki {
    background-image: linear-gradient(90deg, #FAFAFA 50%, #575944 50%);
  }

  .linear-gradient-gray50-to-navy {
    background-image: linear-gradient(90deg, #FAFAFA 50%, #19283C 50%);
  }
}
